<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline"
          >Dashboard</router-link
        >
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Registered Parts</span>
      </div>
    </div>
  </div>

  <div class="container mx-auto px-4 sm:px-0 mt-8">
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Number
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Part Number
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="registered_parts.length > 0"
              >
                <template v-for="item in registered_parts" :key="item.id">
                  <tr>
                    <td
                      class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                    >
                      {{ item.number }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"
                    >
                      <p>
                        {{ item?.part?.part_number }}
                      </p>
                      <p class="text-xs text-gray-700">
                        {{
                          item?.part?.description ||
                          item?.part?.part?.description
                        }}
                      </p>
                    </td>
                    <td class="px-2 py-2 text-sm text-gray-900">
                      <span>
                        {{ item?.customer }}
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-3.5 pr-4 text-sm text-gray-900"
                    >
                      {{ item.human_created_at }}
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="registered_parts === 0"
              >
                <tr>
                  <td
                    colspan="3"
                    class="whitespace-nowrap text-sm text-gray-500 sm:pl-6 text-center p-8"
                  >
                    No data found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => {
    return {
      registered_parts: [],
    };
  },

  async mounted() {
    document.title = "MLE Rep Center | Registered Parts";
    await this.getRegisteredParts();
  },

  methods: {
    async getRegisteredParts() {
      const resp = await axios.get("api/rep-center/registered-parts");
      this.registered_parts = resp.data.registered_parts;
    },
  },
};
</script>
